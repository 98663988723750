import React from "react"
import Helmet from "react-helmet"
import { Container } from "react-bootstrap"

import Layout from '../components/layout'

import heroImg from '../images/leavers/hero-desktop.png';
import heroImgMobile from '../images/leavers/hero-mobile.png';

import classBooks from '../images/leavers/class-books.png';
import themePlaces from '../images/leavers/places.png';
import themeMemories from '../images/leavers/memories.png';
import themeReunion from '../images/leavers/reunion.png';
import themeFlexible from '../images/leavers/flexible.png';
import pack from '../images/leavers/pack.png';
import paperplane from '../images/leavers/paperplane.png';
import { CheckCircle, Slash } from "react-feather";


const LeaversPage = ({ location }) => {

    const showForm = (ev) => {
        ev.preventDefault();
        if (window.FreshworksWidget === undefined) {
            alert('Please enable performance cookies!');
        } else {
            window.FreshworksWidget('open');
        }
    }

    return (
        <>
            <Helmet>
                <title>Year 6 Leavers Book</title>
                <meta name="description" content="" />
            </Helmet>

            <Layout location={location}>
                <div className="y6lHero">
                    <Container>
                        <div className="y6lHero_content">
                            <div className="y6lHero_main">
                                <h1 className="y6lHero_title">Class of 2023: <span>A published celebration</span></h1>
                                <p className="y6lHero_subtitle mb-2">A whole class writing project that allows pupils to use their writing skills as they collaborate with their peers.</p>
                                <p className="mb-2">This unique leavers book will raise self esteem and positively build confidence as your pupils transition to their new schools.</p>
                                <a href={process.env.TEACHER_SIGNUP_LEAVERS_URL} className="primary-btn shadow-btn btn-full text-center d-block">Start Your Book</a>
                            </div>
                            <div className="y6lHero_imgDesktop">
                                <img src={heroImg} alt="Year 6 Levers Book: Free Project" />
                            </div>
                            <div className="y6lHero_imgMobile">
                                <img src={heroImgMobile} alt="Year 6 Levers Book: Free Project" />
                            </div>
                        </div>
                    </Container>
                </div>

                <Container>
                    <h3 className="text-center">A keepsake to treasure</h3>
                    <div className="y6lKeepsake">
                        <div className="mb-3">
                            <p>Send your children home with an anthology of their memories, poems or stories. It's a keepsake they'll treasure for many years to come.</p>
                            <ul className="y6lKeepsake_grades">
                                <li>Yr 4</li>
                                <li>Yr 6</li>
                                <li>Yr 8</li>
                            </ul>
                            <p className="standout mb-2"><b>Complete in as little as a day or as an extended project</b></p>
                            <a href="https://goapps-staging.s3.eu-west-2.amazonaws.com/book_templates/Qm9va1RlbXBsYXRlLTEwNQ==bookTemplateResources-1685637850465.zip" className="primary-btn shadow-btn btn-full text-center d-block mb-1">Download Resources</a>
                        </div>
                        <div>
                            <iframe allowfullscreen="allowfullscreen" className="fp-iframe" src="https://heyzine.com/flip-book/bcad8d83a0.html" style={{border: '1px solid lightgray', width: '100%', height: '400px'}}></iframe>
                        </div>
                    </div>
                </Container>


                <div className="y6lClass">
                    <Container>
                        <div className="y6lClass_content">
                            <img src={classBooks} alt='Books in class' />
                            <div className="y6lClass_handout">
                                <div className="y6lClass_handout_icon"></div>
                                <div className="y6lClass_handout_inner">
                                    Ideal to hand out at a leavers assembly
                                </div>
                            </div>
                            <div className="y6lClass_project">
                                <div className="y6lClass_project_icon"></div>
                                <div className="y6lClass_project_inner">
                                    A perfect post SAT's Writing Project for your Year 6 Leavers
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>

                <div className="y6lPublished">
                    <Container>
                        <h2>Published in 3 easy steps</h2>
                        <ul className="y6lPublished_steps">
                            <li>
                                <div className="y6lPublished_steps_no">1</div>
                                <p>Use one of our suggested themes or set your own</p>
                            </li>
                            <li>
                                <div className="y6lPublished_steps_no">2</div>
                                <p>Your class write & vote on the BoomWriter app</p>
                            </li>
                            <li>
                                <div className="y6lPublished_steps_no">3</div>
                                <p>Hit publish and we'll print & ship the books to your school.</p>
                            </li>
                        </ul>
                        {/* <a href="https://help.boomhub.app/guides/gowrite/get-started-full-anthology.pdf" className="primary-btn shadow-btn y6lPublished_btn">Download guide</a>  */}
                    </Container>
                </div>

                <div className="y6lThemes">
                    <Container>
                        <h2 className="text-center">Use one of our themes or pick your own</h2>
                        <ul className="y6lThemes_list">
                            <li>
                                <h5 className="y6lThemes_list_title">The places you'll go</h5>
                                <p className="y6lThemes_list_subtitle">(Informal letter)</p>
                                <img src={themePlaces} alt='Theme: Places' />
                                <p>Each child write about the places they want to go or jobs they will have in the future</p>
                            </li>
                            <li>
                                <h5 className="y6lThemes_list_title">2043 Reunion</h5>
                                <p className="y6lThemes_list_subtitle">(Imagined Narrative)</p>
                                <img src={themeReunion} alt='Theme: Reunion' />
                                <p>It’s 2043 and your class returns for a party - how has the world and the school changed?</p>
                            </li>
                            <li>
                                <h5 className="y6lThemes_list_title">Do you remember when?</h5>
                                <p className="y6lThemes_list_subtitle">(Poetry)</p>
                                <img src={themeMemories} alt='Theme: Memories' />
                                <p>Each child writes a poem or essay on their best memories of being at the school</p>
                            </li>
                            <li>
                                <h5 className="y6lThemes_list_title">Create your own!</h5>
                                <img src={themeFlexible} alt='Theme: Flexible' />
                                <p>The flexible project allows you to set the brief that is right for your class or year-group.</p>
                            </li>
                        </ul>
                        <h4 className="headingSmall text-center">Download the resources to plan & start your book</h4>
                        <a href="https://goapps-staging.s3.eu-west-2.amazonaws.com/book_templates/Qm9va1RlbXBsYXRlLTEwNQ==bookTemplateResources-1685637850465.zip" className="primary-btn shadow-btn btn-full text-center d-block mb-1">Download Resources</a>
                    </Container>
                </div>

                <div className="y6lPurchase">
                    <Container>
                        <h2>An Affordable, Flexible & Forever Keepsake</h2>
                        <p>Our leavers book is designed to be affordable to all - and a printed book will be a cherished keepsake for-ever!</p>
                        <div className="y6lPurchase_options">
                            <div className="y6lPurchase_options_item">
                                <h4 className="y6lPurchase_options_title">Pre-purchase a class pack to guarantee a copy for every child.</h4>
                                <img src={pack} className='y6lPurchase_options_imgPack' alt='Book Selection Pack' />

                                <p>Class orders come with free, fast shipping direct to the classroom so you can hand these out at your leavers event.</p>
                                <p><b>Tip:</b> Use our included form to allow parents to optionally contribute to the cost.</p>
                                <p>Parents can always purchase additional copies for family & friends from the BoomWriter store.</p>
                                <p>Allow at least 16 days from publishing date for your books to be delivered.</p>
                                <a href={process.env.TEACHER_SIGNUP_LEAVERS_URL} className="primary-btn shadow-btn btn-full mb-2 mt-1">Pre-Purchase & Save</a>
                                <div className="y6lPurchase_options_footer">
                                    <p>Early-bird offer extend through June!</p>
                                    <p>£6.99/child (normally £7.99)</p>
                                </div>
                            </div>
                            <div className="y6lPurchase_options_item">
                                <h4 className="y6lPurchase_options_title">Parent Only Purchase</h4>
                                <img src={paperplane} className='y6lPurchase_options_imgParent' alt='Book Selection Parent' />
                                <p>You can complete the book without purchasing a class pack and simply send home letters to parents. Books are delivered to home addresses. Great for after-school delivery but your pupils will miss the opportunity to sign each other’s books.</p>
                                <div className="y6lPurchase_options_footer">
                                    <p>Books cost £7.99 plus P&P</p>
                                </div>
                            </div>
                        </div>
                        <a href={process.env.TEACHER_SIGNUP_LEAVERS_URL} className="primary-btn shadow-btn y6lPurchase_btn">Start Your Book</a>
                        <div className="y6lPurchase_summary">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="main">Price</th>
                                        <th>
                                            <p><b>Pre Purchase</b></p>
                                            <div>£6.99/pupil</div>
                                        </th>
                                        <th>
                                            <p><b>Parent only</b></p>
                                            <div>£7.99/pupil</div>
                                        </th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="main">Every child guaranteed a copy</td>
                                        <td><CheckCircle /></td>
                                        <td>
                                            <Slash />
                                            <p className="smallText"><i>Some children may not receive a copy</i></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="main">Shipped to school for leavers assembly & group signing</td>
                                        <td><CheckCircle /></td>
                                        <td>
                                            <Slash />
                                            <p className="smallText"><i>Children may not be able to sign each others books</i></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="main">Parents can purchase additional copies after event (delivered to home address)</td>
                                        <td><CheckCircle /></td>
                                        <td><Slash /></td>
                                    </tr> 
                                    <tr>
                                        <td className="main">Parent contribution form</td>
                                        <td><CheckCircle /></td>
                                        <td><Slash /></td>
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                    </Container>
                </div>


            </Layout>
        </>
    )
}

export default LeaversPage